import ru from './lang/ru-RU.yaml';
import cz from './lang/cz-CZ.yaml';

export default {
  legacy: false,
  locale: 'ru',
  fallbackLocale: 'ru',
  messages: {
    ru: ru,
    cz: cz,
  },
  detectBrowserLanguage: false,
};
